import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChatIcon from "./components/ChatIcon";
import Chat from "./components/Chat";
import Layout from "./components/Layout";
import "./App.css";
export default function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/chat-icon" element={<ChatIcon />} />
          <Route
            path="/chat"
            element={
              <div className="biens-chat-window">
                <Chat />
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
