import React, { memo } from "react";
import XIcon from "./Icons/XIcon";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

type Props = {
  photoUrl: string;
  companyName: string;
};
const ChatHeader: React.FC<Props> = ({ photoUrl, companyName }) => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);
  return (
    <div
      className="biens-chat-header"
      style={{ background: chatConfig.hBg || DEFAULT_CONFIG.hBg }}
    >
      <div className="biens-chat-img">
        {photoUrl ? (
          <img src={photoUrl} alt="chat-logo" />
        ) : (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              height={24}
            >
              <path
                fill="#fff"
                d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"
              />
            </svg>
          </div>
        )}
        <div className="green-dot" />
      </div>
      <div className="biens-chat-assistant">
        <h3 style={{ color: chatConfig.asTc || DEFAULT_CONFIG.asTc }}>
          {companyName}
        </h3>
        <span style={{ color: chatConfig.osTc || DEFAULT_CONFIG.osTc }}>
          Online
        </span>
      </div>
      <XIcon
        color={"#757575"}
        height={18}
        className="biens-x-icon"
        onClick={() => {
          window.parent.postMessage(
            "close-chat",
            document.location.ancestorOrigins[0],
          );
        }}
      />
    </div>
  );
};

export default memo(ChatHeader);
