import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

type Identifiers = {
  companyId: string;
  userIdentifier: string;
};

type Location = {
  country_code: string;
  country_name: string;
  city: string;
  IPv4: string;
  state: string;
};
export interface UserSlice {
  identifiers: Identifiers[];
  userIdentifier: string;
  location: Location;
}

const initialState: UserSlice = {
  identifiers: [],
  userIdentifier: "",
  location: {
    country_code: "",
    country_name: "",
    city: "",
    IPv4: "",
    state: "",
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (
      state,
      action: { payload: { location?: Location; companyId: string } },
    ) => {
      const checkCompanyExists = state.identifiers.find(
        (item) => item.companyId === action.payload.companyId,
      );
      if (action.payload.location) {
        state.location = action.payload.location;
      }
      if (!checkCompanyExists) {
        const userIdentifier = uuidv4();
        state.identifiers.push({
          companyId: action.payload.companyId,
          userIdentifier,
        });
        state.userIdentifier = userIdentifier;
      } else {
        state.userIdentifier = checkCompanyExists.userIdentifier;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
