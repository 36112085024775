import { useEffect } from "react";
import axios from "axios";
import { setCompanyData } from "../store/chat/chatSlice";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setUserData } from "../store/chat/userSlice";

const Layout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const originUrl = document.location.ancestorOrigins[0];

    const fetchUserLocation = (companyId: string) => {
      axios
        .get("https://geolocation-db.com/json/")
        .then(({ data }) => {
          dispatch(
            setUserData({
              location: {
                country_code: data.country_code,
                country_name: data.country_name,
                city: data.city,
                IPv4: data.IPv4,
                state: data.state,
              },
              companyId,
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setUserData({
              companyId,
            }),
          );
        });
    };

    axios
      .get("https://api.biens.ai/chat-info", {
        params: {
          origin: originUrl,
        },
      })
      .then(({ data }) => {
        if (data?.data && data?.data?.allowedOrigins?.length) {
          if (process.env.NODE_ENV !== "development" && originUrl) {
            const allowedDomains = data.data.allowedOrigins;
            const checkIsDomainVerified = allowedDomains.includes(originUrl);
            if (checkIsDomainVerified) {
              dispatch(setCompanyData(data.data));
              fetchUserLocation(data.data._id);
            }
          } else {
            dispatch(setCompanyData(data.data));
            fetchUserLocation(data.data._id);
          }
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Outlet />;
};

export default Layout;
