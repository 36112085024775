import { memo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

const ChatFooter = () => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);
  return (
    <div
      className="biens-chat-footer"
      style={{ background: chatConfig.cBg || DEFAULT_CONFIG.cBg }}
    >
      <span>Powered by</span>
      <Link className="biens-link" to="https://biens.ai" target="_blank">
        biens.ai
      </Link>
    </div>
  );
};

export default memo(ChatFooter);
