import { ChatItem } from "../types";
import React from "react";
import Linkify from "linkify-react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

type Props = {
  chat: ChatItem;
};

const ChatBubble: React.FC<Props> = ({ chat }) => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);
  return (
    <Linkify options={{ target: "_blank" }}>
      <div className={`biens-chat-message ${chat.sender}`}>
        <div
          className={`biens-chat-bubble ${chat.sender}`}
          style={{
            background:
              chat.sender === "assistant"
                ? chatConfig.rmBg || DEFAULT_CONFIG.rmBg
                : chatConfig.smBg || DEFAULT_CONFIG.smBg,
          }}
        >
          <h6
            style={{
              color:
                chat.sender === "assistant"
                  ? chatConfig.rmTc || DEFAULT_CONFIG.rmTc
                  : chatConfig.smTc || DEFAULT_CONFIG.smTc,
            }}
          >
            {chat.text}
          </h6>
          <span
            style={{
              color:
                chat.sender === "assistant"
                  ? chatConfig.rmDc || DEFAULT_CONFIG.rmDc
                  : chatConfig.smDc || DEFAULT_CONFIG.smDc,
            }}
          >
            19:36
          </span>
        </div>
      </div>
    </Linkify>
  );
};

export default ChatBubble;
