import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type Props = {
  className?: string;
};
const ChatIcon: React.FC<Props> = ({ className }) => {
  const onClick = () =>
    window.parent.postMessage(
      "open-chat",
      document.location.ancestorOrigins[0],
    );

  const {
    companyData: { photoUrl, chatConfig },
    isServerVerified,
  } = useSelector((state: RootState) => state.chat);
  if (!isServerVerified) {
    return null;
  }
  return (
    <div
      className="biens-chatbot-icon-container"
      style={{
        right:
          chatConfig.dP === "r"
            ? Number(chatConfig.hM) > -1
              ? Number(chatConfig.hM)
              : 40
            : "unset",
        left:
          chatConfig.dP === "l"
            ? Number(chatConfig.hM) > -1
              ? Number(chatConfig.hM)
              : 40
            : "unset",
        bottom: Number(chatConfig.vM) > -1 ? Number(chatConfig.vM) : 40,
      }}
      onClick={onClick}
    >
      {photoUrl ? (
        <img
          src={photoUrl}
          alt="chat-bot"
          className={`chatbot-img ${className || ""}`}
        />
      ) : (
        <div className="chatbot-text">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={24}
          >
            <path
              fill="#fff"
              d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default ChatIcon;
