import { memo } from "react";
import DotIcon from "./Icons/DotIcon";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

const Loading = () => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);
  return (
    <div
      className="biens-loading-container"
      style={{ background: chatConfig.iBg || DEFAULT_CONFIG.iBg }}
    >
      <DotIcon
        height={10}
        color={chatConfig.aSIc || DEFAULT_CONFIG.aSIc}
        className="biens-loading-animation"
      />
      <DotIcon
        height={10}
        color={chatConfig.aSIc || DEFAULT_CONFIG.aSIc}
        className="biens-loading-animation-slow"
      />
      <DotIcon
        height={10}
        color={chatConfig.aSIc || DEFAULT_CONFIG.aSIc}
        className="biens-loading-animation-slowest"
      />
    </div>
  );
};

export default memo(Loading);
