import React, { memo, useEffect, useRef, useState } from "react";
import PaperPlane from "./Icons/PaperPlane";
import { addMessage, setMessageWaiting } from "../store/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "./Loading";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

type Props = {
  socket: WebSocket | undefined;
};
const ChatInput: React.FC<Props> = ({ socket }) => {
  const {
    chat: { companyData, isSocketConnected, isMessageWaiting },
    user: { userIdentifier },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSendMessage = (
    e: React.FormEvent<HTMLFormElement>,
    text: string,
  ) => {
    e.preventDefault();
    if (!socket) {
      return;
    }
    setInput("");
    dispatch(setMessageWaiting(true));
    dispatch(
      addMessage({
        id: new Date().getTime().toString(),
        date: new Date().getTime(),
        text,
        sender: "user",
      }),
    );

    socket.send(
      JSON.stringify({
        action: "webSendMessage",
        webIdentifier: userIdentifier,
        companyId: companyData._id,
        content: text,
      }),
    );
  };

  useEffect(() => {
    if (isSocketConnected && !isMessageWaiting) {
      inputRef.current?.focus();
    }
  }, [isMessageWaiting, isSocketConnected]);

  return (
    <form
      className={"biens-input-area"}
      onSubmit={(e) => handleSendMessage(e, input)}
      style={{
        background:
          isMessageWaiting || !isSocketConnected
            ? companyData.chatConfig.diBg || DEFAULT_CONFIG.diBg
            : companyData.chatConfig.iBg || DEFAULT_CONFIG.iBg,
      }}
    >
      {isMessageWaiting ? <Loading /> : null}
      <input
        type="text"
        value={input}
        ref={inputRef}
        onChange={(e) => setInput(e.target.value)}
        placeholder={companyData.chatConfig.iT || DEFAULT_CONFIG.iT}
        disabled={isMessageWaiting || !isSocketConnected}
        style={{
          color: companyData.chatConfig.iTc || DEFAULT_CONFIG.iTc,
        }}
      />
      <button className="biens-send-button">
        <PaperPlane
          color={
            isMessageWaiting || !input.length || !isSocketConnected
              ? companyData.chatConfig.dSIc || DEFAULT_CONFIG.dSIc
              : companyData.chatConfig.aSIc || DEFAULT_CONFIG.aSIc
          }
          height={24}
        />
      </button>
    </form>
  );
};

export default memo(ChatInput);
