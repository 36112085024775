import React, { LegacyRef } from "react";
import ChatBubble from "./ChatBubble";
import { ChatItem } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { DEFAULT_CONFIG } from "../defaults";

type Props = {
  scrollRef: LegacyRef<HTMLDivElement>;
  messages: ChatItem[];
};

const ChatArea: React.FC<Props> = ({ scrollRef, messages }) => {
  const {
    companyData: { chatConfig },
  } = useSelector((state: RootState) => state.chat);
  return (
    <div
      className="biens-chat-area"
      ref={scrollRef}
      style={{ background: chatConfig.cBg || DEFAULT_CONFIG.cBg }}
    >
      {messages.map((chatItem) => (
        <ChatBubble key={chatItem.id} chat={chatItem} />
      ))}
    </div>
  );
};

export default ChatArea;
