import React, { memo } from "react";

type Props = {
  color?: string;
  height?: number;
  className?: string;
};
const DotIcon: React.FC<Props> = ({
  color = "#000000",
  height = 24,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    height={height}
    width={height}
    className={className}
  >
    <path fill={color} d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
  </svg>
);

export default memo(DotIcon);
