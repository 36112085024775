import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import ChatFooter from "./ChatFooter";
import ChatArea from "./ChatArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  addMessage,
  setMessageWaiting,
  setSocketConnection,
} from "../store/chat/chatSlice";
import { isMobile, browserName } from "react-device-detect";

const Chat = () => {
  const dispatch = useDispatch();
  const {
    chat: { companyData, messages },
    user: { userIdentifier, location },
  } = useSelector((state: RootState) => state);
  const [socket, setSocket] = useState<WebSocket>();
  const messagesScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!socket && companyData.name && userIdentifier) {
      const websocket = new WebSocket(
        "wss://sxskumt7d2.execute-api.us-east-1.amazonaws.com/production/",
      );
      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            action: "webConnect",
            webIdentifier: userIdentifier,
            // @ts-ignore
            language: navigator.language || navigator.userLanguage,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            deviceType: isMobile ? "mobile" : "desktop",
            companyId: companyData._id,
            browserName,
            country_code: location.country_code,
            country_name: location.country_name,
            city: location.city,
            IPv4: location.IPv4,
            state: location.state,
          }),
        );
      };
      websocket.onmessage = (message) => {
        try {
          const data = JSON.parse(message.data);
          if (data.connected) {
            dispatch(setSocketConnection(true));
          }
          if (data.content) {
            dispatch(
              addMessage({
                id: new Date().getTime().toString(),
                date: new Date().getTime(),
                text: data.content,
                sender: "assistant",
              }),
            );
            dispatch(setMessageWaiting(false));
          }
        } catch (e) {
          console.log("socket data couldnt parsed");
        }
      };
      websocket.onclose = () => {
        dispatch(setSocketConnection(false));
      };
      setSocket(websocket);
    }
    return () => {
      socket?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData]);

  useMemo(() => {
    setTimeout(() => {
      messagesScrollRef.current?.scrollTo({
        left: 0,
        top: 9999999,
        behavior: "smooth",
      });
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  if (!companyData.name) {
    return null;
  }

  return (
    <>
      <ChatHeader
        photoUrl={companyData.photoUrl}
        companyName={companyData.name}
      />
      <ChatArea scrollRef={messagesScrollRef} messages={messages} />
      <ChatInput socket={socket} />
      <ChatFooter />
    </>
  );
};

export default memo(Chat);
