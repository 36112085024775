import { combineReducers, configureStore } from "@reduxjs/toolkit";
import chatReducer from "./chat/chatSlice";
import userReducer from "./chat/userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  blacklist: ["chat"],
  storage,
};

const combinedReducers = combineReducers({
  chat: chatReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
